<template>
<one-column>
  <template #content>
      <div class="container contentRight">
          <div class="RediPageContent">
            <h1 class="Headline is-size-3">{{ $t('pages.redi.title') }}</h1>
            <div class="row">
              <div>
                <p
                v-for="(text, index) in $tm('pages.redi.aboutParagraphs')"
                :key="index"
                v-html="text"
                ></p>
              </div>
            </div>
             <div class="row">
                <div class="column">
                  <a :href="appstoreLink" target="_blank">
                    <img
                    :alt="$t('pages.redi.openDownloadButtonTitle')"
                    min-width="100"
                    data-displaymode="Original"
                    src="https://sfc02.cdn.medel.com/images/librariesprovider3/hearing-implants/apps/hearcare-med-el/app_store_badge_us-uk_135x40.svg?auto=format&amp;sfvrsn=d7e52d40_3"
                    :title="$t('pages.redi.openDownloadButtonTitle')">
                  </a>
                </div>
                <div class="column">
                  <a :href="playstoreLink" target="_blank">
                    <img
                    :alt="$t('pages.redi.openDownloadButtonTitle')"
                    min-width="100"
                    data-displaymode="Original"
                    src="https://sfc02.cdn.medel.com/images/librariesprovider3/hearing-implants/apps/hearcare-med-el/google-play-badge.svg?auto=format&amp;sfvrsn=d8e52d40_3"
                    :title="$t('pages.redi.openDownloadButtonTitle')">
                  </a>
                </div>
            </div>
          </div>
        </div>
  </template>
</one-column>
</template>

<script>
import { mapGetters } from 'vuex'
import OneColumn from '../layouts/OneColumn.vue'
import { appSettings } from '@/settings'
import { ios, android } from 'platform-detect'

export default {
  name: 'Redi',

  components: {
    OneColumn
  },

  metaInfo () {
    return {
      title: 'Redi'
    }
  },

  data () {
    return {
      appstoreLink: '',
      playstoreLink: ''
    }
  },

  computed: {
    ...mapGetters('user', [
      'user'
    ])
  },

  created () {
    var country = this.user.country
    // user AT as the default country
    if (!country || !appSettings.services.redi[country]) {
      country = 'AT'
    }

    this.appstoreLink = appSettings.services.redi[country].ios
    this.playstoreLink = appSettings.services.redi[country].android

    if (ios) {
       window.location.assign(this.appstoreLink)
    } else if (android) {
      window.location.assign(this.playstoreLink)
    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.RediPageContent {
  text-align: left;
}

/* overwrite style from OneColumn. */
.ImageContent {
  .main-container-inner {
    p {
       margin: 0 0 2em;
    }
  }
}

.row {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  justify-content: center;
  padding: 20px 0;
}

.column {
  display: flex;
  flex-flow: column;
  padding-inline-end: 20px;
}

</style>
